<template>
  <SupportPageTemplate
    bannerSrc="/assets/image/terms-and-policy_banner.jpg"
    :pageTitle="$t('label.discoverTheJoyOfCookingWithCookXAsia')"
    :pageSubtitle="$t('label.beAnExpertInTheKitchen')"
    :isPDF="true"
    :isCreator="isCreatorTermsOfUse"
    topic="termsOfUse"
  ></SupportPageTemplate>
</template>

<script>
  import SupportPageTemplate from '@/views/components/support/SupportPageTemplate';

  export default {
    name: 'TermsOfUse',
    components: {
      SupportPageTemplate
    },
    data: () => ({
      isCreatorTermsOfUse: false
    }),
    computed: {
      routeQuery() {
        return this.$route.query;
      }
    },
    watch: {
      routeQuery() {
        this.checkRequestedAction();
      }
    },
    created() {
      this.initTermsOfUse();
    },
    methods: {
      checkRequestedAction() {
        let query = this.$route.query;

        if (query.isCreator) {
          this.isCreatorTermsOfUse = true;
        } else {
          this.isCreatorTermsOfUse = false;
        }
      },
      initTermsOfUse() {
        this.checkRequestedAction();
      }
    }
  };
</script>

<style></style>
